
import * as d3 from 'd3'

export default {
  methods: {

    draw_PDF(_stage) {

      switch(_stage) {
        case 'Overall':  
          this.pdf_overall()
          break
        case 'Area':         
          this.pdf_Area()
          break
        case 'Block':     
          this.pdf_Block()
          break
      }
    },

    
    pdf_overall() {
      // model_Area
      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {
        d3.select(`#Overall-Mask`).attr('visibility', 'hidden')
        d3.select(`#Topside-Mask`).attr('visibility', 'hidden')
        d3.select(`#Hull-Mask`)   .attr('visibility', 'hidden')
        d3.select(`#Overall-Group`).attr('transform', `translate(0,250) scale(.8)`)
      })
      
      // canvas_Overall
      this.canvas_Overall = this.svg
      .append('g')
      .attr('id', `CANVAS_Overall`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')


    },
    

    pdf_Area() {
      // model_Area
      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {
        d3.select(`#Overall-Group`).attr('transform', `translate(-200,-300) scale(1.5)`)
        d3.select(`#Overall-Group`).attr('transform', `translate(0,250) scale(.8)`)
        d3.select(`#Hull`).attr('transform', `translate(0,50) scale(.9)`)
        d3.select(`#Topside`).attr('transform', `translate(0,-300) scale(.9)`)
      })
      
      // canvas_Area
      this.canvas_Area = this.svg
      .append('g')
      .attr('id', `CANVAS_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')


    },


    pdf_Block() {
      this.currentArea = 'Hull'
      // model_Block
      this.model_Block = this.svg
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Block, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/${this.currentArea}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
        this.block_List.filter(f=> f.AREA = this.currentArea).forEach(d => {
          d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden')
          d3.select(`#PNG-${d.BLK}`).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
        })
      })

      //canvas_Erection
      this.canvas_Erection = this.svg
      .append('g')
      .attr('id', `CANVAS_ERECTION`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')



    },
    

   

  }
}