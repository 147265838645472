
import * as d3 from 'd3'

export default {
  methods: {

    draw_Dashboard() {

      let cData = this.rawData.find(f=> f.CDATE.substr(0,7) == this.timeline.cutoff.substr(0,7))
      this.currentPro = cData
      
      this.canvas_Dashboard = this.svg
      .append('g')
      .attr('id', `DASHBOARD`)
      .attr('transform', `translate(50,250)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')
      
      this.codes.forEach((d,i) => {
        if(d.type == 'OV') {
          this.draw_Overall(this.canvas_Dashboard, cData, d)
        } else {
          this.draw_Area(this.canvas_Dashboard, cData, d)
        }
        
      })
    },



    draw_Overall(selection, cData, d_) {

      selection
      .append('text')
      .attr('id', `OV_TITLE_${d_.code}`)
      .attr('transform', `translate(10, ${d_.y})`).attr('font-size', 15).attr('fill', '#000')
      .text(d_.title)

      selection
      .append('text')
      .attr('transform', `translate(${this.getNodeElValue(`#OV_TITLE_${d_.code}`, 'width') + 12},${d_.y})`).attr('font-size', 9).attr('fill', '#bcbcbc')
      .text(`Progress`)

      // Plan Cumulative
      let P_Pro = cData[`${d_.code}_EP`]
      let barLen = d_.bar/100
      selection
      .append('rect')
      .attr('x', 10).attr('y', d_.y + 5).attr('width', d_.bar).attr('height', 20).attr('rx', 3)
      .attr('fill', '#F4F5F5').attr('opacity', 0.5)

      selection
      .append('rect')
      .attr('id', `BAR_${d_.code}_EP`)
      .attr('x', 10).attr('y', d_.y + 5)
      .attr('width', P_Pro * barLen).attr('height', 20).attr('rx', 3).attr('fill', d_.pColor)
      .attr('opacity', .5)
      

      selection
      .append('path')
      .attr('id', `POINT_${d_.code}_EP`)
      .attr('transform', `translate(${(P_Pro * barLen) + 7},${d_.y + 26})`)
      .attr('d', 'M0,3.2L3.2,0l3.2,3.2L5.5,4.1c0,0-1.7-1.7-1.6-1.6c0,0,0,5.3,0,5.3H2.6V2.5L0.9,4.1L0,3.2z')
      .attr('fill', '#bcbcbc')

      selection
      .append('text')
      .attr('id', `TEXT_${d_.code}_EP`)
      .attr('x', P_Pro * barLen + 7)
      .attr('y', d_.y + 43)
      .attr('font-size', 11).attr('text-anchor', 'middle').attr('fill', '#757575')
      .text(`${P_Pro}%`)


      // Actual Cumulative
      let A_Pro = cData[`${d_.code}_A`]

      selection
      .append('rect')
      .attr('id', `BAR_${d_.code}_A`)
      .attr('x', 10).attr('y', d_.y + 5)
      .attr('width', A_Pro * barLen).attr('height', 20).attr('rx', 3)
      .attr('fill', `url(#${this.localId}_${d_.aColor}_H)`)
      .attr('opacity', .5)

      selection
      .append('text')
      .attr('id', `TEXT_${d_.code}_A`)
      .attr('x', A_Pro * barLen + 12)
      .attr('y', d_.y + 20)
      .attr('font-size', 12)
      .attr('text-anchor', 'start')
      .attr('fill', '#000')
      .text(`${A_Pro}%`)
    },

    


    draw_Area(selection, cData, d_) {

      let x_ = (d_.sn * this.area.disc) + 30

      let P_Pro = cData[`${d_.code}_EP`]
      let A_Pro = cData[`${d_.code}_A`]

      let P_Y = 16 + (this.area.barH-((P_Pro/100) * this.area.barH))
      let A_Y = 16 + (this.area.barH-((A_Pro/100) * this.area.barH))

      let plan = selection
      .append('g')
      .attr('id', `PLAN_${d_.code}`)
      .attr('transform', `translate(${x_}, ${d_.y})`)

      plan
      .append('line')
      .attr('x1', -20.5).attr('x2', -20.5).attr('y1', 16).attr('y2', this.area.barH + 16)
      .attr('stroke-width', .5).attr('stroke', '#bcbcbc').attr('opacity', 0.3)

      plan
      .append('text')
      .attr('transform', `translate(-4, ${this.area.barH+15}) rotate(-90)`)
      .attr('text-anchor', 'start').attr('font-size', 12).attr('fill', '#333')
      .text(d_.title)

      plan
      .append('rect')
      .attr('x', 0).attr('y',16).attr('width', this.area.barW).attr('height', this.area.barH).attr('rx', 3)
      .attr('fill', '#bcbcbc').attr('opacity', 0.1)

      plan
      .append('rect')
      .attr('id', `BAR_${d_.code}_EP`)
      .attr('x', 0).attr('y',P_Y)
      .attr('width', this.area.barW).attr('height', (P_Pro/100) * this.area.barH).attr('rx', 3)
      .attr('fill', d_.pColor).attr('opacity', .4)

      plan
      .append('path')
      .attr('id', `POINT_${d_.code}_EP`)
      .attr('transform', `translate(${this.area.barW},${P_Y-2}) scale(.7)`)
      .attr('d', 'M3.2,0L0,3.2l3.2,3.2l0.9-0.9c0,0-1.7-1.6-1.6-1.6s5.3,0,5.3,0V2.6H2.5l1.6-1.6L3.2,0z')
      .attr('fill', '#bcbcbc')

      plan
      .append('text')
      .attr('id', `TEXT_${d_.code}_EP`)
      .attr('x', this.area.barW + 7).attr('y',P_Y+3)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start')
      .text((P_Pro == undefined ) ? `0%` : `${P_Pro}%`)


      let act = selection
      .append('g')
      .attr('id', `ACTUAL_${d_.code}`)
      .attr('transform', `translate(${x_}, ${d_.y})`)
      .attr('oapcity', 1)

      act
      .append('rect')
      .attr('id', `BAR_${d_.code}_A`)
      .attr('x', 0).attr('y', A_Y)
      .attr('width', this.area.barW).attr('height', (A_Pro/100) * this.area.barH).attr('rx', 3)
      .attr('fill', d_.aColor).attr('opacity', .3)
      .attr('fill', `url(#${this.localId}_${d_.aColor}_V)`)
      
      act
      .append('text')
      .attr('id', `TEXT_${d_.code}_A`)
      .attr('x', this.area.barW/2).attr('y', A_Y-1)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'middle')
      .text((A_Pro == undefined ) ? `0%` : `${A_Pro}%`)

    },


    

    

   

  }
}