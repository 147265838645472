import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    //pdf
    selectedItems : null,
    getItems      : null,
    
    canvas_Form   : null,
    canvas_Popup  : null,

    rawData       : null,
    blkData       : null,
    selectMenu    : null,
    pop           : {width: 1100, height: 700, x: 0, y: 0, bar: 32},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))

      this.blkData = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.selectMenu = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      if(this.selectMenu.AREA == 'OVERALL') {
        this.pop.x = (1300 - this.pop.width) / 2
        this.pop.y = 150
      } else {
        this.pop.x = (1600 - this.pop.width) / 2
        this.pop.y = (1120 - this.pop.height) / 2
      }

    },

    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },


    set_Gradients() {

      let defs = this.svg.append('defs')

      // Window bar
      let windowBar = defs.append('linearGradient')
      .attr('id', `windowBar`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.4').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.6').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      // // Contents Window
      let contentWindow = defs.append('linearGradient')
      .attr('id', `contentWindow`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', .7)
      contentWindow.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '0').attr('stop-opacity', 1)
      contentWindow.append('stop').attr('stop-color', '#fff').attr('offset', '.1').attr('stop-opacity', 1)

      let filter = defs
      .append('filter').attr('id', 'dropshadow')

      filter.append('feGaussianBlur') .attr('in', 'SourceAlpha').attr('stdDeviation', 7).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur')

      let feMerge = filter.append('feMerge')
      feMerge.append('feMergeNode').attr('in', 'offsetBlur')
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic')
    },

  }
}