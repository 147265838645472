import * as d3 from 'd3'

export default {
  
  methods: {

    header_Phase() {
      let phase = this.Header
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      let y = [57,130]
      this.WBS.phase.forEach((d,i)=> {
        let data = this.rawData.find(f=> // ##### dataSet -> rawData
          f.CDATE == this.timeline.cutoff && 
          f.LEV == d.name && 
          f.AREA == 'Overall' && 
          f.DISC == 'Overall'
        )
        this.draw_pahse(d, y[i], data)
      })
    },
    

    draw_pahse(_d, _y, _data) {

      let phase = this.Header
      .append('g')
      .attr('id', `PHASE_${_d.id}`)
      .attr('transform', `translate(0, ${_y})`)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).style('opacity', 1)
      })
      .on('click', ()=> {

        if(this.current.phase.name == _d.name) return

        d3.select(`#PHASE_RADIO_${this.current.phase.id}`).attr('fill', 'none')
        d3.select(`#PHASE_RADIO_${_d.id}`).attr('fill', '#F35E90')
        this.current.phase.name = _d.name
        this.current.phase.id = _d.id

        d3.selectAll(`.AREA_TEXT`).text(`${this.current.phase.name} Status`)
        d3.select(`#DISC_MAIN_TEXT`).text(`Progress by Discipline for ${this.current.phase.name}(${this.current.area.name})`)
        d3.select(`#DISC_SUB_TEXT`).text(`${this.current.phase.name} Progress Status`)

        this.summary_Controller(_d.name)
        this.draw_Chart_Controller(true)
      })

      phase
      .append('circle')
      .attr('cx', 5).attr('cy', -4).attr('r', 5.5)
      .attr('fill', '#fff')
      .attr('stroke', '#F35E90').attr('stroke-width', 0.5)

      phase
      .append('circle')
      .attr('id', `PHASE_RADIO_${_d.id}`)
      .attr('cx', 5).attr('cy', -4).attr('r', 3)
      .attr('fill', (this.current.phase.id ==_d.id) ? '#F35E90' : 'none')

      phase
      .append('text')
      .attr('transform',`translate(15, 0)`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(_d.name)

      phase
      .append('text')
      .attr('transform',`translate(15, 10)`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text('Cumulative Progress')

      
      let statusY = 45
      let status = this.Header
      .append('g')
      .attr('id', `PHASE_STATUS`)
      .attr('transform', `translate(0.5, ${_y + 0.5})`)

      // bar Chart
      status
      .append('rect')
      .attr('x',15).attr('y',16).attr('width', 250).attr('height', 18).attr('rx', 3)
      .attr('fill', '#FEE7EE').attr('opacity', 0.5)

      status
      .append('rect')
      .attr('x',15).attr('y',16)
      .attr('width', _data.AC_CUM*2.5).attr('height', 18)
      .attr('rx', 3)
      .attr('fill', `url(#${this.localId}_Pink_H)`)
      .attr('opacity', .7)

      status
      .append('text')
      .attr('transform', (_data.AC_CUM < 80) ? `translate(${_data.AC_CUM*2.5 + 17}, ${statusY - 15})` : `translate(${_data.AC_CUM*2.5 + 12}, ${statusY - 15})`)
      .attr('font-size', 11)
      .attr('text-anchor', (_data.AC_CUM < 80) ? 'start' : 'end')
      .attr('fill', '#000')
      .text(`${_data.AC_CUM}%`)

      // Early
      status
      .append('text')
      .attr('transform',`translate(15, ${statusY})`)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start')
      .text('Early:')

      status
      .append('text')
      .attr('transform',`translate(45, ${statusY})`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`${_data.PE_CUM}%`)

      status
      .append('text')
      .attr('transform',`translate(110, ${statusY})`)
      .attr('font-size', 10).attr('text-anchor', 'end')
      .attr('fill', this.set_DeltaColor(_data.DE_CUM, 'no'))
      .text(`${_data.DE_CUM}%`)

      status
      .append('path')
      .attr('transform', `translate(111, ${statusY - 7.5}) scale(.6)`)
      .attr('d', this.set_DeltaColor(_data.DE_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DE_CUM, 'no'))
      .attr('stroke-width', .5)

      // Late
      status
      .append('text')
      .attr('transform',`translate(165, ${statusY})`)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start')
      .text('Late:')

      status
      .append('text')
      .attr('transform',`translate(190, ${statusY})`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`${_data.PL_CUM}%`)

      status
      .append('text')
      .attr('transform',`translate(253, ${statusY})`)
      .attr('font-size', 10).attr('text-anchor', 'end')
      .attr('fill', this.set_DeltaColor(_data.DL_CUM, 'no'))
      .text(`${_data.DL_CUM}%`)

      status
      .append('path')
      .attr('transform', `translate(255, ${statusY - 7.5}) scale(.6)`)
      .attr('d', this.set_DeltaColor(_data.DL_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DL_CUM, 'no'))
      .attr('stroke-width', .5)
    },


    set_DeltaColor(no, type) {
      if (type == 'no') {
        if(no == 0) return '#757575'
        if(no > 0) return '#44A9DF'
        if(no < 0) return '#F35E90'
      } else if (type == 'icon') {
        if(no ==0) return this.icon.zero
        if(no > 0) return this.icon.up
        if(no < 0) return this.icon.down
      }
    },




  }
}