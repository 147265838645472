import * as d3 from 'd3'

export default {
  methods: {

    redraw_Skyline() {

      this.reset_Data()
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      setTimeout(() => { 
        this.canvasSkyline.remove()
        d3.select('#EXCEPTION').remove()
        this.draw_SkylineCharts()
        this.draw_exception()
      }, 500)
      
    },


    redraw_Timeline() {

      this.reset_Data()
      this.canvasTimeline.transition().duration(500).style('opacity',0)
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      setTimeout(() => { 
        this.canvasTimeline.remove()
        this.canvasSkyline.remove()
        d3.select('#EXCEPTION').remove()

        this.draw_Timeline_Week()
        this.draw_SkylineCharts()
        this.draw_exception()
      }, 500)

    },


    reset_Data() {
      // Reset timeline
      this.timeline.refColumn       = this.currentStage.ref, // ***
      this.timeline.refPro          = this.currentStage.pro, // ***
      this.timeline.startDate       = this.currentStage.start,
      this.timeline.endDate         = this.currentStage.end,
      this.timeline.week            = [],
      this.timeline.month           = [],
      this.timeline.year            = [],
      this.timeline.scale           = null
      this.skyline.skyBox.width     = this.currentStage.width

      if(this.currentArea.status == 'OVERALL') {
        this.area_Data = this.rawData.filter(f => 
          f.EXCEPT == '' && 
          f[`${this.currentStage.col}`] !== '' && 
          (f[`${this.currentStage.ref}`] !== null && f[`${this.currentStage.ref}`] !== undefined)
        )
      } else {
        this.area_Data = this.rawData.filter(f =>
          f.AREA ==  this.currentArea.status &&
          f.EXCEPT == '' && 
          f[`${this.currentStage.col}`] !== '' && 
          (f[`${this.currentStage.ref}`] !== null && f[`${this.currentStage.ref}`] !== undefined)
        )
      }
      this.set_SkylineData()
    },


    set_Count(_area) {

      this.stageCodes.forEach((d,i)=> {

        let getVlaue = d.total
        if (this.currentArea.status == 'OVERALL') {
          
          d.total = this.rawData.filter(f => f[`${d.col}`] !== '' && f[`${d.ref}`] !== null).length
          d.ongoing = this.rawData.filter(f => f[`${d.col}`] !== '' && f[`${d.pro}`] > 0 && f[`${d.pro}`] < 100).length
          d.completed = this.rawData.filter(f => f[`${d.col}`] !== '' && f[`${d.pro}`] == 100).length

          this.redraw_Legend(getVlaue, d)

        } else {
          d.total = this.rawData.filter(f => f.AREA == _area && f[`${d.col}`] !== '' && f[`${d.ref}`] !== null).length
          d.ongoing = this.rawData.filter(f => f.AREA == _area && f[`${d.col}`] !== '' && f[`${d.pro}`] > 0 && f[`${d.pro}`] < 100).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f[`${d.col}`] !== '' && f[`${d.pro}`] == 100).length

          this.redraw_Legend(getVlaue, d)
        }
      })

      this.redraw_Skyline()      
    },


    redraw_Legend(before, d_) {

      let height = this.legends.height
      let ongoing = 0
      let completed = 0
      if(d_.total > 0) {
        ongoing = ((d_.ongoing + d_.completed) / d_.total) * height
        completed = (d_.completed / d_.total) * height
      }

      d3.select(`#STAGE_TOTAL_${d_.col}`).transition().duration(500)
      .attr('transform', `translate(15, ${height+11})`)
      .tween("text", function() {
        var i = d3.interpolateNumber(before, d_.total)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}`)
        }
      })

      d3.select(`#STAGE_COMPLETED_${d_.col}`).transition().duration(500)
      .attr('transform', `translate(15, ${height-completed})`)
      .tween("text", function() {
        var i = d3.interpolateNumber(before, d_.completed)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}`)
        }
      })

      d3.select(`#STAGE_ONGOING_${d_.col}`).transition().duration(500)
      .attr('transform', `translate(33, ${height-ongoing})`)
      .tween("text", function() {
        var i = d3.interpolateNumber(before, d_.ongoing)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}`)
        }
      })

      

      d3.select(`#BAR_ONGOING_${d_.col}`).transition().duration(500)
      .attr('transform', `translate( 0, ${height-ongoing})`)
      .attr('height', ongoing)

      d3.select(`#BAR_COMPLETED_${d_.col}`).transition().duration(500)
      .attr('transform', `translate( 0, ${height-completed})`)
      .attr('height', completed)

    }
  }
}
