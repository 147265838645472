import Defs from '../../../../../includes/primitives/colorDefs'
import icon from '../../../../../includes/primitives/icons'
import * as d3 from 'd3'

export default {
  data: () => ({
    MainPhase: 'Construction',

    // Selection
    Canvas_Area:    null,
    Chart_Area:     null,
    Milestone_Area: null,
    Header:         null,

    //pdf
    selectedItems : null,
    getItems      : null,

    rawData:        null,
    dataSet:        null,
    WBS:          { 
      phase: [
        {name: 'Fabrication',           id: 'FB',     },
        {name: 'Mechanical Completion', id: 'MC',     },
      ], 
      area: [
        {name: 'Hull',                  id: 'HULL',   },
        {name: 'Topside',               id: 'TOP',    },
        {name: 'LQ',                    id: 'LQ',     },
      ], 
      disc: [
        {name: 'Structural',            id: 'S',      },
        {name: 'HVAC/Arch.',            id: 'H',      },
        {name: 'Piping',                id: 'P',      },
        {name: 'Mechanical',            id: 'M',      },
        {name: 'E&I',                   id: 'E',      },
      ],
    },
    current: {
      phase:  { name: 'Fabrication',          id: 'FB', },
      area:   { name: 'Overall',              id: 'OV', },
      disc:   { name: 'Overall',              id: 'OV', },
    },

    milestone:      null,
    timeline:       null,
    chart:          null,
    lineChart:      [],
    axis:           null,
    icon:           icon.arrow,
    grid:           null,
    scale:          {primary: null, secondary: null},

    barColors:[ '#83D2F5', '#FFDA00', '#F7BACF', '#8BC248', '#44A9DF', ],

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    setDefaultValues(){     

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.milestone = JSON.parse(JSON.stringify(this.Queries.SQL2))
      
      
      // Cnavas ---------------------------------------------------------------------------
      this.canvas = {
        width           : sql1['CANVAS_WIDTH'],
        height          : sql1['CANVAS_HEIGHT'],
        
        padding : {
          top           : sql1['PADDING_TOP'],
          right         : sql1['PADDING_RIGHT'],
          bottom        : sql1['PADDING_BOTTOM'],
          left          : sql1['PADDING_LEFT'],
        },
        chart : {
          width         : sql1['CANVAS_WIDTH'] - sql1['PADDING_LEFT'] - sql1['PADDING_RIGHT'],
          height        : sql1['CANVAS_HEIGHT'] - sql1['PADDING_TOP'] - sql1['PADDING_BOTTOM'],
        },
      }


      // Timeline ------------------------------------------------------------------------------------

      this.timeline = {
        length          : this.canvas.chart.width,
        startDate       : this.rawData[0].CDATE,
        endDate         : this.rawData[this.rawData.length - 1].CDATE,
        weekEnd         : sql1['WEEKEND'],
        cutoff          : sql1['CUTOFF'],
        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }

      // LineChart
      let codes = sql1.REF_CODE.replace(/\s/g, '').split('/')
      codes.forEach((d,i)=> {
        this.lineChart.push({
          code    : sql1.REF_CODE.replace(/\s/g, '').split('/')[i],
          name    : sql1.NAME.replace(/\s/g, '').split('/')[i],
          type    : sql1.LINE_TYPE.replace(/\s/g, '').split('/')[i],
          sWidth  : sql1.LINE_SIZE.replace(/\s/g, '').split('/').map(Number)[i],
          sColor  : sql1.LINE_COLOR.replace(/\s/g, '').split('/')[i],
          Opacity : sql1.LINE_OPACITY.replace(/\s/g, '').split('/').map(Number)[i],
        })
      })



      // Grids
      this.grid = []
      for (let i = 0; i < 6; i++) {
        this.grid.push(this.canvas.chart.height - ((this.canvas.chart.height / 5) * i))
      } 
      
      this.init_gmx_TimelineValues(this.timeline, 'day')
      
    },

    set_Gradients() {
      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'LightBlue', [.3, .5, 1, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'Yellow', [.3, .5, 1, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'Pink', [.3, .5, 1, .5], this.localId)

      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'Pink', [.5, 1, .5, 0.3], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'LightBlue', [.5, 1, .7, 0.3], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'Yellow', [.5, 1, .5, 0.3], this.localId)


      let defs = this.svg.append('defs')
      let headRect = defs.append('radialGradient')
      .attr('id', `GradientShadow`).attr('cx', .5).attr('cy', .7).attr('r', .3)
      headRect.append('stop').attr('stop-color', '#000000').attr('offset', '.06').attr('stop-opacity', 1)
      headRect.append('stop').attr('stop-color', '#E6E6E6').attr('offset', '1').attr('stop-opacity', 1)

      // Background
      let shadow = defs.append('linearGradient')
      .attr('id', `GradientBG`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 1)
      shadow.append('stop').attr('stop-color', '#FCFCFC').attr('offset', '0').attr('stop-opacity', .5)
      shadow.append('stop').attr('stop-color', '#D9D9D9').attr('offset', '1').attr('stop-opacity', .5)
    },

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        pdfCheckstatus:this.pdfCheckstatus,
        pdfCheckdisc:this.pdfCheckdisc
      }
    },
  }
}