import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Overall_Canvas() {
      
      this.canvas_Overall = this.svg
      .append('g')
      .attr('id', `CANVAS_Overall`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')


      this.canvas_Overall
      .append('text')
      .attr('transform', `translate(1350, 50)`)
      .attr('font-size', 24).attr('fill', '#000')
      .text('CANVAS - OVERALL')







      
      this.selectedItems = {  
        // ...this.selectedItems,
        stage: 'Overall',
        area: this.currentArea
      }

    },
    
  }
}