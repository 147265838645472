<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'   
     
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  
  mounted() {
    this.register(this.draw)
  },

  methods: {
    
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
    },

    drawing() {

      this.set_Gradients()
      this.set_LocalValues()
      this.draw_Forms()
      

      // for Exporting PDF file
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      this.getItems.stage = null // 'Block'

      if(this.getItems.stage !== null) {
        this.draw_PDF(this.getItems.stage)
      }
      else {
        this.draw_Sequence()
        this.draw_Timeline_Month()
        this.draw_Dashboard()
        this.draw_Milestone()
      }
      
    },  

  }
}
</script>



<!-- <style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 170px;

    overflow: hidden;
  }
</style> -->