
export default {
  data: () => ({
    rawData:    [],
    
    config:     {},
    tube:       {
      scale: null,
    },
    columns:    {},
    filters:    {},

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
      zoom()
    */

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      let code =                sql1.REF_CODE.replace(/\s/g, '').split('/')
      this.config = {
        x:                  sql1.X,
        y:                  sql1.Y,
        tubeColor:          sql1.TUBE_COLOR,
        height:             sql1.TUBE_HEIGHT,
        tickness:           sql1.TUBE_TICKNESS,
        base: {
          height:           sql1.BASE_HEIGHT,
          padding:          sql1.BASE_PADDING,
        },
        value:       {
          EARLY:            this.rawData[code[0]],
          LATE:             this.rawData[code[1]],
          ACTUAL:           this.rawData[code[2]],
          EARLY_CUM:        this.rawData[code[3]],
          LATE_CUM:         this.rawData[code[4]],
          ACTUAL_CUM:       this.rawData[code[5]],
        },
      }
      this.tube = {
        x:                  sql1.X,
        y:                  sql1.Y,
        color:              sql1.TUBE_COLOR,
        height:             sql1.TUBE_HEIGHT,
        tickness:           sql1.TUBE_TICKNESS,
        base: {
          tSize:            sql1.BASE_TEXT_SIZE,
          tColor:           sql1.BASE_TEXT_COLOR,
          height:           sql1.BASE_HEIGHT,
          padding:          sql1.BASE_PADDING,
        },
        value : {
          tColor:           sql1.TUBE_VALUE_COLOR,
          tSize:            sql1.TUBE_VALUE_SIZE,
        },
      }
      this.columns = {
        code:               sql1.REF_CODE.replace(/\s/g, '').split('/'),

        value : {
          tColor:           sql1.TUBE_TEXT_COLOR,
          tSize:            sql1.TUBE_TEXT_SIZE,
        },
        main : {
          tColor:           sql1.MAIN_COLOR,
          tSize:            sql1.MAIN_SIZE,
        },
        sub : {
          hColor:           sql1.SUB_HEADER_COLOR,
          hSize:            sql1.SUB_HEADER_SIZE,
          vColor:           sql1.SUB_VALUE_COLOR,
          vSize:            sql1.SUB_VALUE_SIZE,

        },
        cPadding:           sql1.TEXT_CUM_PADDING,
        tDiff:              sql1.TEXT_SIZE_DIFF,
        tPadding:           sql1.TEXT_PADDING,
      }
      this.columns.values = {
        LATE:                this.rawData[code[0]],
        EARLY:               this.rawData[code[1]],
        ACTUAL:              this.rawData[code[2]],
        EARLYC:              this.rawData[code[3]],
        LATEC:               this.rawData[code[4]],
        ACTUALC:             this.rawData[code[5]],
      }

      // console.log(this.columns)
    },
    
      
      


    
    set_ColorDefs(selection){

      let Shadow = selection
      .append('defs') // Shadow Left
      .append('radialGradient')
      .attr('id', `${this.localId}_Shadow`)

      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      let TubeBase = selection
      .append('defs') // d.base.height Cylinder
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBase`)

      TubeBase.append('stop').attr('stop-color', '#a9adb0').attr('offset', '0.00').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '0.60').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '1.00').attr('stop-opacity', '1')

      let TubeBox = selection
      .append('defs') // Tube Box
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBox`)

      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '0.00').attr('stop-opacity', '0.50')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.30').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#fff').attr('offset', '0.60').attr('stop-opacity', '0.01')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.80').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '1.00').attr('stop-opacity', '0.50')

      let TubeCap = selection
      .append('defs') // Tube Cap
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeCap`)

      TubeCap.append('stop').attr('stop-color', '#efefef').attr('offset', '0.00').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.41').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#ececec').attr('offset', '0.59').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#e8e8e8').attr('offset', '1.00').attr('stop-opacity', '0.90')
    }
  }
}