import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Block(_d) {

      this.model_Block = this.svg
      .append('svg')
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.currentArea == 'Hull') {
        this.loadSvg(
          this.model_Block, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/${this.currentArea}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(400,350) scale(.8)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.currentArea == 'Topside') {
        this.loadSvg(
          this.model_Block, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/${this.currentArea}/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(200,310) scale(.9)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.draw_Block_Canvas(_d) // ----->
        }) 
      }

    },

  }
}