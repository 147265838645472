
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
     
      this.canvas_Form
      .append('rect')
      .attr('x', 0).attr('y', 1110)
      .attr('width', 1600).attr('height', 10)
      .attr('fill', '#D71638')
      
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)


      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text('Block Division for Construction')

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#44A9DF')
      .text('Block Division Rev.2 (29 Aug 2024)  / Block Schedule Rev.0 (29 Aug 2024)')

      // Load a Legend SVG
      this.loadSvg(
        this.canvas_Form, {
        x: 25, y: 965,
        url : '/Visualization/BlockDivision/includes/Legends_Horizontal.svg'
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
      })

      // // Load a JGS logo SVG
      this.loadSvg(
        this.canvas_Form, {
        x   : 1460, y   : 1060,
        url : '/icon/Logo/JGS.svg'
      })

    },


    

    

   

  }
}