
import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Overall() {

      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {

        d3.select(`#MODEL_AREA`).style('opacity', 0).attr('transform', `translate(0,0) scale(1)`)
        
        d3.select(`#Overall-MASK`).attr('visibility', 'hidden').style('opacity', 0).attr('cursor', 'pointer').call(this.call_Overall)
        d3.select(`#Topside-MASK`).attr('visibility', 'hidden').style('opacity', 0).attr('cursor', 'pointer').call(this.call_Area, 'Topside')
        d3.select(`#Hull-MASK`)   .attr('visibility', 'hidden').style('opacity', 0).attr('cursor', 'pointer').call(this.call_Area, 'Hull')

        // Fade-in Effect
        d3.select(`#MODEL_AREA`)
        .transition().duration(500).style('opacity', 1).attr('transform', `translate(50,100) scale(.9)`)

        setTimeout(() => { 
          d3.select(`#Overall-MASK`).attr('visibility', 'display')
          this.draw_Overall_Canvas() // ----->
        }, 750)
        
      })
      
     
    }

  }
}