
import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Area() {

      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {

        d3.select(`#Hull`).style('opacity', 0)
        d3.select(`#Topside`).style('opacity', 0)

        d3.select(`#MODEL_AREA`).attr('transform', `translate(50, 150) scale(.9)`)
        
        d3.select(`#Topside-MASK`).attr('visibility', 'hidden').attr('cursor', 'pointer').call(this.call_Area, 'Topside')
        d3.select(`#Hull-MASK`)   .attr('visibility', 'hidden').attr('cursor', 'pointer').call(this.call_Area, 'Hull')
        d3.select(`#Overall-MASK`).attr('visibility', 'hidden').call(this.call_Overall)
        

        d3.select(`#Hull`)
        .transition().duration(750).attr('transform', `translate(0, 150) scale(.9)`).style('opacity', 1)
        d3.select(`#Topside`)
        .transition().duration(750).attr('transform', `translate(0, -200) scale(.9)`).style('opacity', 1)
        
        setTimeout(() => { 
          d3.select(`#Topside-MASK`).attr('visibility', 'display')
          d3.select(`#Hull-MASK`).attr('visibility', 'display')

          this.draw_Area_Canvas() // ----->
        }, 750)
      })

      
      
    },

  }
}