<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'   
     
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  
  mounted() {
    this.register(this.draw)
  },

  methods: {
    
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
    },

    
    drawing() {

      this.setDefaultValues()
      this.set_Gradients()
      this.draw_Forms()

      // for Exporting PDF file
      console.log(this.getSelectItems)
      this.getItems = JSON.parse(JSON.stringify(this.getSelectItems))
      console.log(this.getItems)
      
      if(this.getItems) {
        console.log(this.getSelectItems)
        // if(this.getItems) {
        // this.draw_Timeline_Month()
        this.draw_Timeline_Week()
        this.draw_SkylineCharts()
        this.draw_exception()
        this.draw_Legends()
        this.draw_Detail()
        // this.draw_Search()
          
        if(this.getItems.area) this.currentArea = this.getItems.area
        if(this.getItems.stage) this.currentStage = this.getItems.stage

        this.draw_PDF()
        // }
      } else {
        // this.draw_Timeline_Month()
        this.draw_Timeline_Week()
        this.draw_SkylineCharts()
        this.draw_exception()
        this.draw_Legends()
        this.draw_Detail()
        // this.draw_Search()
      }
      
    },  

  }
}
</script>



<!-- <style lang="scss" scoped>
  .svg_wrapper {
    width: 1300px;
    height: 170px;

    overflow: hidden;
  }
</style> -->