import * as d3 from 'd3'
import moment from 'moment'
import icon from '../../../../../includes/primitives/icons'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    //pdf
    selectedItems:    null,
    getItems:         null,
    
    canvas_Form:      null,
    canvas_Sequence:  null,
    canvas_Dashboard: null,
    canvas_SVG:       null,

    selectedStep:     null,
    timeline:         {},

    progress:         null,
    stepData:         null,
    rawData:          [],
    cData:            {},
    currentPro:       null,
    icon:             icon.arrow,

    area: {
      barW:           36,
      barH:           120,
      disc:           100
    },

    codes: [
      {sn: 0, type: 'OV',  code: 'CO', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Construction',          bar: 500, y: 50  },
      {sn: 0, type: 'OV',  code: 'MO', pColor: '#FCE3EB', aColor: 'Pink',      title: 'Mechanical Completion', bar: 300, y: 310 },
      {sn: 0, type: 'FAB', code: 'CH', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Hull',                  bar: 100, y: 100 },
      {sn: 1, type: 'FAB', code: 'CT', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Topside',               bar: 100, y: 100 },
      {sn: 2, type: 'FAB', code: 'CL', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'LQ',                    bar: 100, y: 100 },
      {sn: 3, type: 'FAB', code: 'CR', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Transportation',        bar: 100, y: 100 },
      {sn: 4, type: 'FAB', code: 'CI', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Integration',           bar: 100, y: 100 },
      {sn: 0, type: 'MC',  code: 'MH', pColor: '#FCCADB', aColor: 'Pink',      title: 'Hull',                  bar: 100, y: 360 },
      {sn: 1, type: 'MC',  code: 'MT', pColor: '#FCCADB', aColor: 'Pink',      title: 'Topside',               bar: 100, y: 360 },
      {sn: 2, type: 'MC',  code: 'ML', pColor: '#FCCADB', aColor: 'Pink',      title: 'LQ',                    bar: 500, y: 360 },
    ],

    transport: [
      { BLK: 'NP11', EF: '2025-05-14', align: 'end', y: 0},
      { BLK: 'SP11', EF: '2025-06-03', align: 'end', y: 0},
      { BLK: 'WP11', EF: '2025-06-10', align: 'start', y: 0},
      { BLK: 'EN11', EF: '2025-07-14', align: 'middle', y: 0},
      { BLK: 'EC11', EF: '2025-07-14', align: 'middle', y: 15},
      { BLK: 'WN11', EF: '2025-08-11', align: 'middle', y: 0},
      { BLK: 'WC11', EF: '2025-08-11', align: 'middle', y: 15},
      { BLK: 'EN21', EF: '2025-10-04', align: 'end', y: 0},
      { BLK: 'EC21', EF: '2025-10-04', align: 'end', y: 15},
      { BLK: 'WN21', EF: '2025-10-15', align: 'start', y: 0},
      { BLK: 'WC21', EF: '2025-10-15', align: 'start', y: 15},
    ],

    module: [
      { BLK: 'CENTRAL', ES: '2026-03-16', align: 'middle', width: 44, y: 0 },
      { BLK: 'SOUTH',   ES: '2026-03-31', align: 'middle', width: 34, y: 15},
      { BLK: 'NORTH',   ES: '2026-04-14', align: 'middle', width: 34, y: 0 },
      { BLK: 'LQ',      ES: '2026-05-14', align: 'middle', width: 30, y: 0 },
      { BLK: 'HELI',    ES: '2026-06-11', align: 'middle', width: 30, y: 0 },
      { BLK: 'FLARE',   ES: '2027-02-24', align: 'middle', width: 30, y: 0 },
    ]

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.progress = JSON.parse(JSON.stringify(this.DataItems))
      this.stepData = this.Queries.SQL1 // Timeline
      let sql2 = this.Queries.SQL2[0] // Timeline

      this.stepData.forEach(d => {
        let data = this.progress.find(f=> f.CDATE == d.CDATE)
        this.rawData.push(data)
      })

      this.rawData.forEach((d,i) => {
        d.SN = i
        d.cDate = moment(d.CDATE).toDate()

        this.codes.forEach(c=> {
          d[`${c.code}_EP`] = this.setUndefined(d[`${c.code}_EP`])
          d[`${c.code}_A`] = this.setUndefined(d[`${c.code}_A`])
        })
      })


      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        baseX           : 200,
        baseY           : 200,
        length          : 1500,
        cutoff          : sql2.CUTOFF,
        cDate           : moment(sql2.CUTOFF).toDate(),
        cMonth          : new Date(sql2.CUTOFF).getMonth()+1,
        startDate       : `${this.rawData[0].CDATE.substr(0,7)}-01`,
        endDate         : `${this.rawData[this.rawData.length-1].CDATE.substr(0,7)}-28`,
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : 35,
      }
      this.init_gmx_Set_Timeline_Month(this.timeline)

    },


    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)


        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)

      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'Pink', [.1, 1, .1, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'LightBlue', [.1, 1, .1, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'LightBlue', [.3, .5, 1, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'Pink', [.3, .5, 1, .5], this.localId)

      
    },

  }
}