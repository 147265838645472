import * as d3 from 'd3'

export default {
  methods: {

    draw_PDF(_stage) {

      

    },
  }
}