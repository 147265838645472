
import * as d3 from 'd3'

export default {
  methods: {

    draw_Sequence() {
      
      this.canvasForm = this.svg
      .append('g')
      .attr('id', `TIMESTEP`)
      .attr('transform', `translate(50,160)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')

      this.canvasForm
      .append('text')
      .attr('x', 0).attr('y', -12)
      .attr('font-size', 10).attr('text-anchor', 'start').attr('fill', '#44A9DF')
      .text(`Cutoff date at the end of the month`)

      // cutoff Date
      this.canvasForm
      .append('rect')
      .attr('x', this.timeline.scale(this.timeline.cDate)-3).attr('y', 22)
      .attr('width', 6).attr('height', 15)
      .attr('fill', '#83D2F5').attr('opacity', .5)

      this.canvasForm
      .append('line')
      .attr('x1', 0).attr('y1', 10).attr('x2', this.timeline.length).attr('y2', 10)
      .attr('fill', '#fff').attr('stroke', '#F4F5F5').attr('stroke-width', 2)

      this.canvasForm
      .append('line')
      .attr('id', `LINE`)
      .attr('x1', 0).attr('y1', 10).attr('x2', this.timeline.scale(this.timeline.cDate)).attr('y2', 10)
      .attr('fill', '#fff').attr('stroke', '#83D2F5').attr('stroke-width', 2)

      

      // Draw Circle
      this.rawData.forEach((d,i) => {

        let cutoff = false
        if (this.timeline.cutoff.substr(0,7) == d.CDATE.substr(0,7)) {
          cutoff = true
          this.selectedStep =i
        }
        
        let step = this.canvasForm
        .append('g')
        .attr('id', `TIMESTEP`)
        .attr('transform', `translate(${this.timeline.scale(d.cDate)},0)`)
        .attr('cursor', 'pointer')
        .call(this.call_Step, d, i) // <---------------------- Event.js

        step
        .append('rect')
        .attr('x', -7).attr('y', -12)
        .attr('width', 14).attr('height', 30).attr('opacity', 0)

        step
        .append('text')
        .attr('id', `STEP_${i}`)
        .attr('transform', `translate(0, 0)`)
        .attr('font-size', 11).attr('fill', '#44A9DF').attr('text-anchor', 'middle')
        .text(`${i+1}`)

        step
        .append('circle')
        .attr('id', `OUT_CIRCLE_${i}`) 
        .attr('cx', 0).attr('cy', 10)
        .attr('r', cutoff ? 5 : 0)
        .attr('fill', '#fff').attr('stroke', '#83D2F5')
        .attr('stroke-width', 1).attr('opacity', 1)

        step
        .append('circle')
        .attr('id', `IN_CIRCLE_${i}`)
        .attr('cx', 0).attr('cy', 10).attr('r', 2.5)
        .attr('fill', cutoff ? '#83D2F5' : '#fff')
        .attr('stroke', cutoff ? '#83D2F5' : '#bcbcbc')
        .attr('stroke-width', 1)
      })


      this.canvas_SVG = this.svg
      .append('svg')
      .attr('id', `SVG_TAG`)
      .attr('transform', `translate(0,0)`)
      .attr('width', 1500)

      let file = this.canvas_SVG
      .append('g')
      .attr('id', `SVG_FILE_${this.selectedStep}`)
      .attr('transform', `translate(0,0)`).attr('opacity', 1)

      this.loadSvg(
        file, {
        x   : 50,
        y   : 100,
        url : `/Visualization/ErectionTimeline/${this.timeline.cutoff.substr(0,7)}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
      })

    },

  }
}