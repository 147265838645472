import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_2PE(_d) {
      console.log(`draw_Model_2PE${_d}`)
      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Topside/${this.currentPE}.svg`
      }).then(() => {
        console.log('this.currentPE', this.currentPE)
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 0).attr('transform', `translate(0,0) scale(1)`)
        d3.select(`#MODEL_2PE`).transition().duration(1000).style('opacity', 1)

        this.block_List.filter(f=> f.AREA === this.currentPE).forEach(d => {

          d3.select(`#${d.BLK}`).attr('opacity', 0)
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
         
          d3.select(`#PNG-${d.BLK}`)
          .transition().duration(750).delay(1000).attr('transform', `translate(${d.X2},${d.Y2}) scale(.75)`)
        })

        setTimeout(() => { 
          this.block_List.filter(f=> f.AREA == this.currentPE).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(1000).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.75)`)
            d3.select(`#LINES`).transition().duration(300).delay(1000).style('opacity', 1)
          })
        }, 1200)

        setTimeout(() => { 
          this.block_List.filter(f=> f.AREA == this.currentPE).forEach(d => {
            
            d3.select(`#${d.BLK}`).transition().duration(500).style('opacity', 1)
            d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_2PE, d)
          })
          d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
          this.draw_2PE_Canvas(_d) // ----->
        }, 3000)
        
      })

    },



    back_Model_2PE(_d) {
      console.log(`back_Model_2PE${_d}`)
      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Topside/${this.currentPE}.svg`
      }).then(() => {

        this.draw_2PE_Canvas(_d) // ----->
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 1).attr('transform', `translate(0,0) scale(1)`)
 
        this.block_List.filter(f=> f.AREA == this.currentPE).forEach(d => {
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
          d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X3},${d.Y3}) scale(0.75)`)
          d3.select(`#${d.BLK}`).style('opacity', 0)
        })
        
        setTimeout(() => { 
          this.block_List.filter(f=> f.AREA == this.currentPE).forEach(d => {
            d3.select(`#${d.BLK}`).transition().duration(500).style('opacity', 1)
            d3.select(`#LINES`).transition().duration(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
          })
        }, 750)

        setTimeout(() => { 
          this.block_List.filter(f=> f.AREA == this.currentPE).forEach(d => {
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'display').attr('cursor', 'pointer').call(this.call_2PE, d)
          }) 
        }, 1500)
      })

    },


  }
}