import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValues          from './set_LocalValues'

import lmx_draw_Header          from './draw_Header'
import lmx_header_Pahse         from './header_Pahse'
import lmx_header_Area          from './header_Area'
import lmx_header_Disc          from './header_Disc'


import lmx_Axis                 from './draw_Axis'
import lmx_Charts               from './draw_Charts'
import lmx_Summary              from './draw_Summary'
import lmx_Legends              from './draw_Legends'
import lmx_Milestone            from './draw_Milestone'

import lmx_events               from './Events'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValues,
    lmx_events,

    lmx_draw_Header,
    lmx_header_Pahse,
    lmx_header_Area,
    lmx_header_Disc,
    
    lmx_Axis,
    lmx_Charts,
    lmx_Summary,
    lmx_Legends,
    lmx_Milestone,
  ],

}
