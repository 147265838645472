import * as d3 from 'd3'

export default {
  methods: {

    draw_PDF() {
      
      d3.select(`#AREA_OVERALL`).attr('opacity', 0.8) 
      d3.select(`#AREA_text_OVERALL`).attr('font-weight', 400).attr('fill', '#333')
      d3.select(`#AREA_in_OVERALL`).attr('fill', '#fff')

      d3.select(`#AREA_${this.currentArea.status}`).attr('opacity', 1)
      d3.select(`#AREA_text_${this.currentArea.status}`).attr('font-weight', 500).attr('fill', '#000')
      d3.select(`#AREA_in_${this.currentArea.status}`).attr('fill', '#F35E90')

      d3.select(`#STAGE_BOX_${this.stageCodes[0].ref}`).attr('opacity', 0)
      d3.select(`#STAGE_TEXT_${this.stageCodes[0].ref}`).attr('fill', '#757575')
      d3.select(`#STAGE_TOT_${this.stageCodes[0].ref}`).attr('fill', '#757575')

      d3.select(`#STAGE_BOX_${this.currentStage.ref}`).attr('opacity', 1)
      d3.select(`#STAGE_TEXT_${this.currentStage.ref}`).attr('fill', '#000')
      d3.select(`#STAGE_TOT_${this.currentStage.ref}`).attr('fill', '#000')

      this.set_Count(this.currentArea.status)
      this.redraw_Timeline()

    },
  }
}