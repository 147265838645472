import * as d3 from 'd3'

export default {
  
  methods: {

    header_Area() {

      let x = [275, 425, 575]

      this.WBS.area.forEach((d,i)=> {
        let data = this.rawData.find(f=> // ##### dataSet -> rawData
          f.CDATE == this.timeline.cutoff && 
          f.LEV == this.current.phase.name && 
          f.AREA == d.name 
          && f.DISC == 'Overall'
        )
        this.draw_Area(d, x[i], data)
      })

    },
    

    draw_Area(_d, _x, _data) {

      let area = this.Header
      .append('g')
      .attr('id', `AREA_${_d.id}`)
      .attr('transform', `translate(${_x + 6}, 15)`)
      .style('font-family', 'roboto')

      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).style('opacity', 1)
      })
      .on('click', ()=> {
        d3.select(`#AREA_RADIO_${this.current.area.id}`).attr('fill', 'none')
        if(_d.id == this.current.area.id) {
          this.current.area.name = 'Overall'
          this.current.area.id = 'OV'
        } else {
          d3.select(`#AREA_RADIO_${_d.id}`).attr('fill', '#44A9DF')
          this.current.area.name = _d.name
          this.current.area.id = _d.id
        }
       
        this.WBS.disc.forEach((d,i)=> {
          this.draw_Disc_Summary(d)
        })
        d3.select(`#DISC_MAIN_TEXT`).text(`Progress by Discipline for ${this.current.phase.name}(${this.current.area.name})`)

        this.draw_Chart_Controller(true)
      })

      area
      .append('circle')
      .attr('cx', 6).attr('cy', -4).attr('r', 5)
      .attr('fill', '#fff')
      .attr('stroke', '#44A9DF').attr('stroke-width', 0.5)
      area
      .append('circle')
      .attr('id', `AREA_RADIO_${_d.id}`)
      .attr('cx', 6).attr('cy', -4).attr('r', 2.5)
      .attr('fill', (this.current.area.id ==_d.id) ? '#44A9DF' : 'none')
      area
      .append('text')
      .attr('transform',`translate(15, 0)`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(_d.name)
      area
      .append('text')
      .attr('class', `AREA_TEXT`)
      .attr('transform',`translate(15, 10)`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`${this.current.phase.name} Status`)

      let bar = this.Header
      .append('g')
      .attr('transform', `translate(${_x }, 45)`)

      bar
      .append('rect')
      .attr('x',15).attr('y', 10).attr('width', 35).attr('height', 120).attr('rx', 3)
      .attr('fill', '#B4E3FA').attr('opacity', 0.5)

      let actY = 130 - _data.AC_CUM * (120/100)
      bar
      .append('rect')
      .attr('id', `BAR_${_d.id}`)
      .attr('x',15).attr('y', actY)
      .attr('width', 35).attr('height', _data.AC_CUM * (120/100))
      .attr('rx', 3)
      .attr('fill', `url(#${this.localId}_LightBlue_V)`)


      // This Week
      bar
      .append('text')
      .attr('id', `${_d.id}_AC_WK`)
      .attr('transform',`translate(32.5, 6)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'middle')
      .text(`${_data.AC_WK}%`)
      bar
      .append('circle')
      .attr('cx', 70).attr('cy', 3).attr('r', 2).attr('fill', '#757575')
      bar
      .append('line')
      .attr('x1', 50).attr('x2', 70).attr('y1', 3).attr('y2', 3).attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      bar
      .append('text')
      .attr('transform',`translate(74, 6)`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`This Week`)


      // Cumulative Text & Line
      bar
      .append('line')
      .attr('id', `LINE_${_d.id}_H`)
      .attr('x1', 50).attr('x2', 60).attr('y1', actY).attr('y2', actY).attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      bar
      .append('line')
      .attr('id', `LINE_${_d.id}_V`)
      .attr('x1', 60).attr('x2', 60).attr('y1', actY).attr('y2', 63).attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      bar
      .append('line')
      .attr('x1', 60).attr('x2', 70).attr('y1', 63).attr('y2', 63).attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      bar
      .append('circle')
      .attr('cx', 70).attr('cy', 63).attr('r', 2).attr('fill', '#757575')
      bar
      .append('text')
      .attr('transform',`translate(74, 66)`)
      .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`Cumulative`)


      // Cumulative - Early
      let y_ = 80
      bar
      .append('text')
      .attr('transform',`translate(74, ${y_})`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`Early`)
      bar
      .append('text')
      .attr('id', `${_d.id}_PE_CUM`)
      .attr('transform',`translate(74, ${y_+12})`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`${_data.PE_CUM}%`)
      bar
      .append('text')
      .attr('id', `${_d.id}_DE_CUM`)
      .attr('transform',`translate(130, ${y_+12})`)
      .attr('font-size', 10).attr('text-anchor', 'end')
      .attr('fill', this.set_DeltaColor(_data.DE_CUM, 'no'))
      .text(`${_data.DE_CUM}%`)
      bar
      .append('path')
      .attr('id', `ICON${_d.id}_DE_CUM`)
      .attr('transform', `translate(130, ${y_ +12 - 7.5}) scale(.6)`)
      .attr('d', this.set_DeltaColor(_data.DE_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DE_CUM, 'no'))
      .attr('stroke-width', .5)


      // Cumulative - Late
      y_ = 110
      bar
      .append('text')
      .attr('transform',`translate(74, ${y_})`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`Late`)
      bar
      .append('text')
      .attr('id', `${_d.id}_PL_CUM`)
      .attr('transform',`translate(74, ${y_+12})`).attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`${_data.PL_CUM}%`)
      bar
      .append('text')
      .attr('id', `${_d.id}_DL_CUM`)
      .attr('transform',`translate(130, ${y_+12})`)
      .attr('font-size', 10).attr('text-anchor', 'end').attr('fill', this.set_DeltaColor(_data.DL_CUM, 'no'))
      .text(`${_data.DL_CUM}%`)
      bar
      .append('path')
      .attr('id', `ICON${_d.id}_DL_CUM`)
      .attr('transform', `translate(130, ${y_ +12 - 7.5}) scale(.6)`)
      .attr('d', this.set_DeltaColor(_data.DL_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DL_CUM, 'no'))
      .attr('stroke-width', .5)
    },

  }
}